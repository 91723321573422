.modal-body {
  padding: 30px 0;
}

.loader {
  display: inline-block;
}

.line-scale > div {
  background-color: #3f6ad8;
  width: 4px;
  height: 35px;
  margin: 2px;
  display: inline-block;
  animation: line-scale 0.5s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(1) {
  animation-delay: -0.3s;
}

.line-scale > div:nth-child(2) {
  animation-delay: -0.1s;
}

.line-scale > div:nth-child(3) {
  animation-delay: 0s;
}

.line-scale > div:nth-child(4) {
  animation-delay: -0.1s;
}

.line-scale > div:nth-child(5) {
  animation-delay: -0.3s;
}

@keyframes line-scale {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
