.forms-wizard {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    display: table;
    table-layout: fixed;
    border-radius: .25rem;
    border: 0;
}
.forms-wizard-alt .forms-wizard li {
    font-size: .88rem;
}
.forms-wizard li:hover {
    color: #6c757d;
}

a {
    color: #3f6ad8;
    text-decoration: none;
    background-color: transparent;
}
.forms-wizard li em {
    font-style: normal;
    font-size: 1.5rem;
    background: #ced4da;
    color: #fff;
    text-align: center;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    display: block;
    margin: 0 auto 0.5rem;
    position: relative;
    z-index: 7;
    transition: all .2s;
}
.forms-wizard li.active em {
    background: #3f6ad8;
    color: #fff;
}

.forms-wizard li {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    padding: 1rem 0;
    color: #adb5bd;
    position: relative;
}

.color-fuente{
    color: #005293
}
.card-header {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: .625rem;
    height: 3.5rem;
    background-color: white;
}
.card-header, .card-title {
    text-transform: uppercase;
    color: rgba(13,27,62,0.7);
    font-weight: bold;
    font-size: .88rem;
}
p{
    text-align:justify;
    margin-top: 0;
    margin-bottom: 1rem;
}
.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    overflow: hidden;
    background: #e9ecef;
}

.bgCustom{
    background-color: #005293
}
.clearfix{
    display: flex;
    justify-content: end;
}