/* Fondo de lado izquierdo */
.backgroundOficina {
  background-image: url('../images/oficinaMinsur.jpg');
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.backgroundOficina::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  opacity: 0.6;
  height: 100vh;
}

.backgroundOficinaContent {
  position: relative;
  text-align: center;
  margin: 0 6rem;
}

.slider-content {
  color: #fff;
}
.slider-content h3 {
  font-size: 1.75rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
}
.slider-content p {
  font-size: 1rem;
  opacity: 0.7;
}
.customButton {
  background-color: #005293;
}
.small {
  font-size: 80%;
}

.app-container {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  height: 100vh;
  width: 100%;
  color: #495057;
  margin: 0;
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
}
/* .app-container * {
    border: 1px solid red;
} */

h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
h5 h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}
.card {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-width: 0;
  transition: all 0.2s;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}
.footerLetterTitle {
  font-size: 0.9em;
}
.footerLetter {
  color: gray;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(26, 54, 126, 0.125);
  font-size: 0.88rem;
}
.font-family {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
}
.btn {
  font-size: 0.8rem;
  font-weight: 500;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  overflow: hidden;
  background: #e9ecef;
}

.app-theme-white {
  background: #f1f4f6;
}
a {
  text-decoration: none;
}

.customButton {
  background-color: #005293;
}
.footerCustom {
  color: gray;
  float: left;
}
.footerTitleCustom {
  color: gray;
  font-size: 0.9em;
}
